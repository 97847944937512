<template>
  <div class="body">
    <div>
      <el-row align="middle" style="height: 100%;">
        <el-upload :show-file-list="false" :on-change="processUsers" :auto-upload="false" action="#">
          <el-button :loading="isLoginUserLoading" type="primary">
            登陆
          </el-button>
        </el-upload>
        <el-button style="margin-left: 12px;" type="primary" :onclick="() => {
          createNewTask(1)
        }">
          新建发送
        </el-button>
        <el-button style="margin-left: 12px;" type="primary" :onclick="() => {
          createNewTask(2)
        }">
          拉群
        </el-button>
        <el-button style="margin-left: 12px;" type="primary" :onclick="clearLoginUsers">清空登陆用户</el-button>
      </el-row>
    </div>
    <div style="background-color: #f8f8f8; margin-top: 24px;">
      <el-row>
        <div style="width: 200px;">
          <el-col align="middle">
            <el-row style="background-color: lightgray; padding-top: 8px; padding-bottom: 8px;">
              <el-text style="font-size: 10pt; color: lightslategrey; margin-left: 12px;">当前登陆用户</el-text>
            </el-row>
            <el-row v-for="user in loginUsers" :key="loginUsers.indexOf(user)" :index="loginUsers.indexOf(user)"
              align="middle" style="width: 100%; margin-top: 16px">
              <el-avatar style="margin-left: 12px" :src="user.avatarUrl"></el-avatar>
              <el-text style="margin-left: 12px; color: #333">{{ user.firstname + user.lastname }}</el-text>
            </el-row>
          </el-col>
          <el-row style="margin-top: 12px;" v-if="isUsersLoading" align="middle" justify="center">
            <el-icon class="is-loading">
              <Loading />
            </el-icon>
            <el-text style="margin-left: 8px;">加载用户中...</el-text>
          </el-row>
        </div>
        <div>
          <el-table border :data="taskList" style="width: 100%; height: 100vh">
            <el-table-column prop="timestamp" label="日期" width="226">
            </el-table-column>
            <el-table-column prop="typStr" label="类型" width="80">
            </el-table-column>
            <el-table-column prop="quantity" label="发送数量" width="80">
            </el-table-column>
            <el-table-column prop="content" label="内容" show-overflow-tooltip width="260">
            </el-table-column>
            <el-table-column prop="completed_quantity" label="成功" width="80">
            </el-table-column>
            <el-table-column prop="failed_quantity" label="失败" width="80">
            </el-table-column>
            <el-table-column prop="uuid" label="任务编号" show-overflow-tooltip width="200">
            </el-table-column>
            <el-table-column label="进度条" width="200">
              <template #default="scope">
                <el-progress
                  :percentage="Math.round((scope.row.completed_quantity + scope.row.failed_quantity) / scope.row.quantity * 100)"
                  striped-flow striped />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
    </div>

    <el-dialog v-model="isSelectSenderVisible">
      <el-row>
        <el-text>请选择发送用户：</el-text>
        <el-select v-model="currSenderIdx" placeholder="Select" style="width: 240px; margin-left: 12px;">
          <el-option v-for="item in loginUsers" :key="loginUsers.indexOf(item)" :label="item.firstname + item.lastname"
            :value="loginUsers.indexOf(item)" />
        </el-select>
      </el-row>

      <template #footer>
        <el-button type="primary" @click="confirmSender">
          确定
        </el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="isSendFormVisible" :before-close="dialogBeforeClose">
      <el-text>消息内容</el-text>
      <el-input v-model="inputMessage" placeholder="请输入发送内容" type="textarea"
        style="margin-top: 12px; margin-bottom: 12px; " />
      <el-text>温馨提示：用户群组比较多时，加载时间会比较长，后台会循环加载最近的会话获取群组信息，请耐心等待。</el-text>
      <el-button style="margin-top: 12px;" :onclick="changeSelectType">刷新当前联系人/群组</el-button>
      <el-row align="middle">
        <el-radio-group style="margin-top: 12px;" v-model="selectType" @change="changeSelectType">
          <el-radio value="1" border :disabled="isContactLoading || isGroupLoading || isReceiverLoading">个人</el-radio>
          <el-radio value="2" border :disabled="isContactLoading || isGroupLoading || isReceiverLoading">群组</el-radio>
          <el-radio value="3" border :disabled="isContactLoading || isGroupLoading || isReceiverLoading">批量导入</el-radio>
        </el-radio-group>
        <el-row align="middle" style="margin-left: 12px;"
          v-if="isContactLoading || isGroupLoading || isReceiverLoading">
          <el-icon class="is-loading">
            <Loading />
          </el-icon>
          <el-text>
            {{ isContactLoading ? '正在加载联系人' : (isGroupLoading ? '正在加载群组' : '正在加载导入用户') }}
          </el-text>
        </el-row>
      </el-row>
      <el-transfer style="margin-top: 12px;" v-model="chooseReceiverList"
        :data="selectType == '1' ? contactList : (selectType == '2' ? groupList : receiverList)">
        <template #left-footer>
          <el-upload v-if="selectType == '3'"
            style="margin-left: 8px; height: 100%; display: flex; align-items: center;" :show-file-list="false"
            :on-change="processReceiver" :auto-upload="false" action="#">
            <el-button size="small">
              导入用户
            </el-button>
          </el-upload>
        </template>
      </el-transfer>

      <template #footer>
        <el-button :loading="isSendLoading" type="primary" @click="didSendNowChooseMsg">
          确定
        </el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="isCreateGroupVisible">
      <el-col>
        <el-row>
          <el-text>请导入用户拉群文件：</el-text>
          <el-upload :show-file-list="false" :on-change="processCreateGroup" :auto-upload="false" action="#">
            <el-button size="small">
              点击导入
            </el-button>
          </el-upload>
        </el-row>
        <div style="margin-top: 20px;">
          <el-text
            style="font-size: small; color: #999">温馨提示：系统会将导入的用户拉成群组，请导入正确的格式文件，在导入成功之前请不要关闭此窗口，若有疑问，请联系客服。</el-text>
        </div>
      </el-col>


      <template #footer>
        <el-button type="primary" :loading="isCreateGroupLoading">
          确定
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="isCreateGroupResultVisible">
      <el-result icon="success" title="请求成功" sub-title="创建群组成功">
        <template #extra>
          <el-button type="primary" @click="createNewTask">点击打开新建发送</el-button>
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { POST } from './utils/requests';

export default {
  name: 'App',
  data() {
    return {
      isSendFormVisible: false,  //发送消息form dialog
      isSelectSenderVisible: false,   //选择发送人的dialog
      isCreateGroupVisible: false,    //创建群组的dialog
      isCreateGroupResultVisible: false,     //处理结果
      isUsersLoading: false,    //是否正在刷新用户
      isContactLoading: false,      //联系人数据加载中
      isGroupLoading: false,  //群组数据加载中
      isReceiverLoading: false, //发送用户加载中
      isSendLoading: false,   //是否发送中
      isCreateGroupLoading: false, //创建群组加载中
      isLoginUserLoading: false,  //登陆用户是否在加载
      inputMessage: '',     //输入框内容
      loginUsers: [],            //当前用户数据
      selectType: '',       //选择发送个人还是群组
      chooseReceiverList: [],      //选择接收者
      contactList: [],       //联系人列表
      groupList: [],        //群组列表
      receiverList: [],     //批量导入的发送用户
      taskList: [],         //已创建的任务
      currSenderIdx: 0,   //当前发送者的下表,
      actionFlag: 0,     //当前行动指向,用于控制选择完用户的dialog的下一步动作
    }
  },
  mounted() {
    this.fetchTaskList()
    this.relogin()
  },
  methods: {
    //登陆
    async login(username, password) {
      const response = await POST('/login', {
        username,
        password
      })
      const result = response.data.data
      if (response.data.code == 200) {
        const { token, user } = result
        var tokens = localStorage.getItem('tokens')
        tokens = tokens == undefined ? [] : JSON.parse(tokens)
        tokens.push(token)
        localStorage.setItem('tokens', JSON.stringify(tokens))

        var u = user
        u.token = token
        this.loginUsers.push(u)
      } else {
        ElMessage.error(username + '登陆失败，请检查账号是否正常！')
      }
    },
    //刷新后从本地token重新登陆
    async relogin() {
      const tokens = localStorage.getItem('tokens')
      if (tokens == undefined) {
        ElMessage.error('没有发现登陆的账号，请先登陆！')
        return
      }
      JSON.parse(tokens).map(async (value) => {
        await this.getUserInfo(value)
      })
    },
    async getUserInfo(token) {
      this.isUsersLoading = true
      const response = await POST('/userinfo', {
        token
      })
      this.isUsersLoading = false
      if (response.data.code == 200) {
        var data = response.data.data
        data.token = token
        this.loginUsers.push(data)
        return data
      } else {
        if (response.data.msg == 'Token file has expired') {
          const tokens = localStorage.getItem('tokens')
          const newTokens = JSON.parse(tokens).filter((value) => value != token)
          localStorage.setItem('tokens', JSON.stringify(newTokens))
        }
      }
      return null
    },
    //获取任务日志列表
    async fetchTaskList() {
      const { data } = await POST('/taskList')
      this.taskList = data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((value) => {
        if (value.typ != 1 && value.typ != 2) {
          value.typStr = "未知"
        } else {
          value.typStr = value.typ == 1 ? "个人" : "群组";
        }
        return value
      });
    },
    //获取联系人数据
    async fetchContactList() {
      this.isContactLoading = true
      const response = await POST('/contactList', {
        token: this.loginUsers[this.currSenderIdx].token
      })
      if (response.data.code == 200) {
        this.contactList = response.data.data.map((value) => {
          value.label = value.display_name || value.displayname
          value.key = value.mri
          return value
        })
      }
      this.isContactLoading = false
    },
    //获取群组数据
    async fetchGroupList() {
      console.log('asdasda')
      this.isGroupLoading = true
      const response = await POST('/groupList', {
        token: this.loginUsers[this.currSenderIdx].token
      })
      if (response.data.code == 200) {
        if (this.groupList != undefined && this.groupList.length > 0) {
          this.groupList = this.groupList.concat(response.data.data.map((value) => {
            value.label = value.properties.topic
            value.key = value.id
            return value
          }))
        } else {
          this.groupList = response.data.data.map((value) => {
            value.label = value.properties.topic
            value.key = value.id
            return value
          })
        }
      }
      this.isGroupLoading = false
    },
    //创建任务dialog任务
    createNewTask(action) {
      this.actionFlag = action
      this.isSelectSenderVisible = true
    },
    //确定发送者
    confirmSender() {
      if (this.actionFlag == 0) {
        return
      }
      this.isSelectSenderVisible = false
      if (this.actionFlag == 1) {
        this.isSendFormVisible = true
      } else if (this.actionFlag == 2) {
        this.isCreateGroupVisible = true
      }
    },
    //现在发送选择性的消息
    async didSendNowChooseMsg() {
      this.isSendLoading = true
      const response = await POST('/createTask', {
        token: this.loginUsers[this.currSenderIdx].token,
        msg: this.inputMessage,
        num: this.chooseReceiverList.length,
        is_private: this.selectType == "1" || this.selectType == "3" ? 1 : 2
      })
      await this.fetchTaskList()
      this.isSendLoading = false
      this.isSendFormVisible = false
      const token = this.loginUsers[this.currSenderIdx].token
      const newSelTyp = this.selectType
      const msg = this.inputMessage
      const receiverList = this.chooseReceiverList
      this.dialogBeforeClose()
      if (response.data.code == 200) {
        const uid = response.data.data
        if (newSelTyp == "1" || newSelTyp == "3") {
          receiverList.map((value) => {
            POST('/sendMsg', {
              token: token,
              channel_id: value,
              msg,
              uid
            }).then((res) => {
              this.taskList.map((value) => {
                if (value.uuid == uid) {
                  if (res.data.code == 200) {
                    value.completed_quantity = value.completed_quantity + 1
                  } else {
                    value.failed_quantity = value.failed_quantity + 1

                  }
                }
              })
            })
          })
        } else if (newSelTyp == "2") {
          receiverList.map((value) => {
            POST('/sendMsg', {
              token: token,
              channel_id: value,
              msg,
              is_private: false,
              uid
            }).then((res) => {
              this.taskList.map((value) => {
                if (value.uuid == uid) {
                  if (res.data.code == 200) {
                    value.completed_quantity = value.completed_quantity + 1
                  } else {
                    value.failed_quantity = value.failed_quantity + 1

                  }
                }
              })
            })
          })
        }
      } else {
        ElMessage.error(response.data.msg)
      }
    },
    //radio改变选择类型 个人 群组 导入用户
    changeSelectType() {
      if (this.selectType == "1") {
        this.fetchContactList()
      } else if (this.selectType == "2") {
        this.fetchGroupList()
      }
    },
    //处理文件一统
    processFile(file, onload) {
      const reader = new FileReader();
      reader.onload = onload;
      reader.readAsText(file.raw);
    },
    //批量导入登陆用户
    processUsers(file) {
      this.isLoginUserLoading = true
      this.processFile(file, async (event) => {
        const fileContent = event.target.result;
        const fileList = fileContent.split('\n')
        for (let index = 0; index < fileList.length; index++) {
          const element = fileList[index];
          const elements = element.split(' ')
          const username = elements[0]
          const password = elements[1]
          await this.login(username, password)
        }
        this.isLoginUserLoading = false
      })
    },
    //批量导入用户，用于发送
    processReceiver(file) {
      this.isReceiverLoading = true
      this.processFile(file, async (event) => {
        const fileContent = event.target.result;
        const fileList = fileContent.split('\n')
        const promises = fileList.map(async (value) => {
          const response = await POST('/search', {
            token: this.loginUsers[this.currSenderIdx].token,
            name: value
          })
          this.receiverList = this.receiverList.concat(response.data.data.map((value) => {
            value.label = value.name
            value.key = value.skypeId
            return value
          }))
        })
        await Promise.all(promises)
        this.isReceiverLoading = false
      })
    },
    //拉群，读取文件
    processCreateGroup(file) {
      this.isCreateGroupLoading = true
      this.processFile(file, async (event) => {
        const fileContent = event.target.result;
        const fileList = fileContent.split('\n')
        var searchList = []
        const promises = fileList.map(async (value) => {
          const response = await POST('/search', {
            token: this.loginUsers[this.currSenderIdx].token,
            name: value
          })

          searchList = searchList.concat(response.data.data)
        })
        await Promise.all(promises)
        const response = await POST('/createGroup', {
          token: this.loginUsers[this.currSenderIdx].token,
          member_ids: searchList.map((value) => {
            return value.skypeId
          })
        })
        this.isCreateGroupLoading = false
        this.isCreateGroupVisible = false
        if (response.data.code == 200) {
          this.isCreateGroupResultVisible = true
        } else {
          ElMessage.error('创建群组失败，请检查文件格式，若有疑问，请联系客服')
        }
      })
    },
    clearLoginUsers() {
      localStorage.clear()
      ElMessage.success('清空登陆用户成功，请刷新后重新使用本软件')
    },
    // dialog将要关闭前的钩子
    dialogBeforeClose(done) {
      this.chooseReceiverList = []
      this.actionFlag = 0
      this.receiverList = []
      this.contactList = []
      this.groupList = []
      this.selectType = ''
      this.inputMessage = ''
      if (done != undefined) {
        done()
      }
    },
  },
}
</script>

<style>
.body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>

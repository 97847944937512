import axios from 'axios';

export async function POST(path, payload, retryNum) {
    var retryCount = retryNum ?? 0
    var config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    if (payload != undefined && payload.token != undefined) {
        config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': payload.token
            }
        }
    }
    var response = null
    try {
        response = await axios.post('http://119.28.61.58:8989' + path, payload, config)
    } catch (error) {
        if (retryCount < 3) {
            retryCount++
            return POST(path, payload, retryCount)
        }
    }
    return response    
}